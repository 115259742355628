import React from 'react';
import { Image } from 'semantic-ui-react';

const DeliveryInfo = () => {
  return (
    <div className="delivery-info info-card">
      <div className="info-card__image">
        <Image src="/assets/delivery.jpg" alt="Home Delivery" />
      </div>

      <div className="info-card__main">
        <h3 className="info-card__headline">
          Free home delivery for prescriptions
        </h3>
        <p className="info-card__description">
          A service that extends beyond the pharamcy. Home delivery for
          prescriptions are available twice every day. We can have your scripts
          ready for you and deliver them to your door. Please contact the
          pharmacy for further details about this service.
        </p>
      </div>
    </div>
  );
};

export default DeliveryInfo;
