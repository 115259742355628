import React from 'react';
import { Icon, Responsive } from 'semantic-ui-react';
import EmployeeCards from '../employees/EmployeeCards';
import Hours from '../hours/Hours';
import Location from '../location/Location';
import ContactInfo from '../contact/ContactInfo';

const About = () => {
  return (
    <div className="about-page">
      <div className="about-page__intro">
        <h3 className="about-page__intro__primary">
          East Lindfield Pharmacy has serviced the local community for over 60
          years.
        </h3>
        <h4 className="about-page__intro__secondary">
          We are located at 5 Hughes Place in the East Lindfield shopping
          village next door to the IGA Plus. We are committed to providing
          exceptional service and providing for all your pharmacy needs.{' '}
        </h4>

        <h4 className="about-page__intro__secondary">
          One of our friendly, knowledgeable and professional pharmacists is
          always on hand to answer your questions and address any concerns you
          may have with your prescription or over the counter medicines and
          other health issues.
        </h4>

        <h4 className="about-page__intro__secondary">
          We pride ourselves on efficient and accurate prescription dispensing ,
          and the provision of up-to-date health advice. Have a look at our
          services page for more information on some of the services we provide.
        </h4>

        <h4 className="about-page__intro__secondary">
          The current owners, Elias and Alf, have been running the pharmacy
          since Dec 2018. With over 40 years experience in pharmacy between
          them, you can be assured that your health is in good hands.
        </h4>

        <h4 className="about-page__intro__secondary">
          Come in and say hello, we are looking forward to meeting you.
        </h4>
      </div>

      <div className="about-page__container">
        <Responsive minWidth={768}>
          <h1 className="container-heading">
            Location, hours and ways to contact us
          </h1>
        </Responsive>

        <div className="about-page__location">
          <Responsive maxWidth={767}>
            <h1 className="section-heading">Where to find us</h1>
          </Responsive>
          <Location />
        </div>

        <div className="hours-contact-container">
          <div className="about-page__hours">
            <Responsive maxWidth={767} as="h1" className="section-heading">
              Our business hours
            </Responsive>

            <div className="about-page__hours__items">
              <Icon className="about-page__hours__icon" name="clock outline" />
              <Hours />
            </div>
          </div>

          <div className="about-page__contact">
            <Responsive maxWidth={767}>
              <h1 className="section-heading">Ways to contact us</h1>
            </Responsive>
            <ContactInfo />
          </div>
        </div>
      </div>

      <div className="about-page__employees">
        <h1 className="section-heading">Meet the team</h1>
        <EmployeeCards />
      </div>
    </div>
  );
};

export default About;
