import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import Logo from '../../assets/logo.svg';
import { brand } from '../../data/constants';
import SideDrawerMenu from './SideDrawerMenu';
import SideDrawerInstructions from './SideDrawerInstructions';
import ContactInfoItem from '../contact/ContactInfoItem';

const SideDrawer = props => {
  const { handleClose, history, isOpen } = props;
  const classes = isOpen ? 'side-drawer side-drawer--open' : 'side-drawer';

  const onLogoClicked = () => {
    history.push('/');
    handleClose();
  };

  return (
    <div className={classes}>
      <div className="side-drawer__header">
        <div className="side-drawer__header__main">
          <div
            className="side-drawer__header__main__logo"
            onClick={onLogoClicked}
          >
            <Image src={Logo} alt="logo" />
          </div>

          <div className="side-drawer__header__main__close">
            <Icon name="close" size="small" onClick={handleClose} />
          </div>
        </div>

        <div className="side-drawer__header__contact">
          <ContactInfoItem iconName="phone" detail={brand.TELEPHONE} />
          <ContactInfoItem iconName="mail" detail={brand.DEFAULT_EMAIL} />
        </div>
      </div>

      <SideDrawerMenu closeSideDrawer={handleClose} history={history} />
      <SideDrawerInstructions />
    </div>
  );
};

export default SideDrawer;
