import React from 'react';
import { Divider, Responsive, Icon } from 'semantic-ui-react';
import HomePageFooter from './HomePageFooter';
import ServiceInfo from '../services/ServiceInfo';
import DeliveryInfo from '../delivery/DeliveryInfo';
import EmployeeCardCarousel from '../employees/EmployeeCardCarousel';

const Home = () => {
  return (
    <div className="home-page">
      <div className="home-page__intro">
        <h3>Welcome to our website. Thanks for taking the time to visit us.</h3>

        <h4>
          East Lindfield Pharmacy has serviced the local community for over 60
          years. You can find us at 5 Hughes Place, East Lindfield.
        </h4>

        <Responsive minWidth={768} as="h5">
          We've included a summary of how to use our website in our help
          section. To view it, tap the HELP menu option at the top of any page.
        </Responsive>

        <Responsive maxWidth={767} as="h5">
          We've included a summary of how to use our website in our help
          section. To view it, tap{' '}
          <Icon name="help circle" className="home-page__intro__help" /> at the
          top of any page.
        </Responsive>

        <h5>
          In the meantime, here's some quick info about the pharmacy to get you
          started.
        </h5>
      </div>

      <div className="home-page__info-cards">
        <ServiceInfo />
        <Responsive maxWidth={767} as={React.Fragment}>
          <Divider />
        </Responsive>

        <DeliveryInfo />
        <Responsive maxWidth={767} as={React.Fragment}>
          <Divider />
        </Responsive>
      </div>

      <div className="home-page__carousel">
        <h2 className="home-page__carousel__title">Our team</h2>
        <EmployeeCardCarousel />
      </div>

      <HomePageFooter />
    </div>
  );
};

export default Home;
